@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind components;
@tailwind utilities;

:root{
  --westland-red: #cf3e36;
  --westland-red-darker: #b9342d

}

.ex {
  fill: #4bb543;
}

.draw {
  transform: scaleY(0);
  transform-origin: center bottom;
  animation: draw 1.5s linear forwards;
}

@keyframes draw {
  to {
    transform: scaleY(1);
  }
}

.font-roboto{
  font-family: "Roboto", serif;
}

.error{
  color: #ff0033
}

.success{
  color: #4bb543
}

@layer components {
  .nav-button{
    @apply mt-2;
    color: #FFFFFF;
  }
}


.test {
  background-image: url(../src/assets/imgs/test.png);
}

.westland-red{
  color: var(--westland-red);
  border-color: var(--westland-red);
}

.westland-red-button{
  color: var(--westland-red);
  border-color: var(--westland-red);
}

.westland-red-button:hover{
  background-color: rgba(207, 62, 54, .2);
  transition: background-color .2s ease-in-out;
}

.upload-button {
  font-size: 1rem;
  line-height: 1.5rem;
  height: 3rem;
  width: 8rem;
  background-color: var(--westland-red);
  color: white;
  border-color: white;
}

.upload-button:hover {
  background-color: white;
  color: var(--westland-red);
  border-color: var(--westland-red);
}

.error-button{
  font-size: 1rem;
  line-height: 1.5rem;
  height: 3rem;
  width: 15rem;
  background-color: var(--westland-red);
  color: white;
  border-color: white;

}

.error-button:hover{
  background-color: white;
  color: var(--westland-red);
  border-color: var(--westland-red);
}

.loading-circle{
  stroke-width: 10px;
  stroke-linecap: round;
  fill: transparent;
}

.container-color {
  background-color: #FFFFFF;
}

.container-height {
  min-height: 85vh;
}
.mt-auto {
  margin-top: auto;
}

.w-80vw{
  width: 80vw;
}

.h-90vh{
  height: 90vh;
}

.h-60vh{
  height: 60vh;
}


.dark-charcoal{
  background-color: #333333;
}

.no-jobs-celebration{
  background: linear-gradient(rgba(255,255,255,0.58), rgba(255,255,255,0.58)), url(../src/assets/imgs/Celebration-Robot.svg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.pending-robot{
  background: linear-gradient(rgba(255,255,255,0.58), rgba(255,255,255,0.58)), url(../src/assets/imgs/WarehouseCrane.svg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.score-card{
  width: 20%;
}

html {
  background-color: #ECF0F3;
  margin: 0;
  font-family: "Work Sans", "Roboto", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

